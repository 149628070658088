.mdc-button {
  & > .mat-icon {
    vertical-align: middle !important;
    width: 1.25rem !important;
    height: 1.25rem !important;
    font-size: 1.25rem !important;

    & + span {
      margin-left: 0;
    }
  }

  .mdc-button__label {
    white-space: nowrap;
  }

  &.mat-mdc-unelevated-button:not(:disabled)[color='success'] {
    background-color: mat.get-color-from-palette($app-success, default);
    color: white;
  }

  &.mat-mdc-outlined-button:not(:disabled)[color='success'] {
    color: mat.get-color-from-palette($app-success, default);
  }
}

a[class*='-button']:not([class*='-menu']),
button:not([class*='-menu']) {
  span {
    display: inline-block;
  }
}

a[class*='-button'] {
  &:hover {
    text-decoration: none;
  }
}

.mat-mdc-icon-button {
  //opacity: 0.75;
  transition: background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  font-size: inherit !important;

  &.mat-mdc-button-base {
    line-height: 1rem;
  }

  &:focus,
  &:focus-visible {
    .mat-mdc-focus-indicator,
    .mat-mdc-button-persistent-ripple,
    .mat-mdc-focus-indicator::before {
      border-radius: inherit;
    }
  }

  &:hover .mat-mdc-button-persistent-ripple,
  &:active .mat-mdc-button-persistent-ripple {
    border-radius: inherit;
  }

  &:not([disabled]):hover {
    opacity: 1;
    //background-color: rgba(0, 0, 0, 0.2);
  }
}

// This is Mathematica-specific, at least to the current style
.mat-mdc-button,
.mat-mdc-flat-button,
.mat-mdc-stroked-button {
  border-radius: $default-border-radius !important;
}

.icon-link {
  display: inline-block;
  align-items: center;

  .mat-icon + span {
    margin-left: 0.25rem;
    vertical-align: top;
  }
}
