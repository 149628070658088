[class*='flex-'] {
  display: flex;
}

.flex-row {
  flex-flow: row nowrap;
}

.flex-row-reverse {
  flex-flow: row-reverse nowrap;
  justify-content: flex-end;
}

.flex-column {
  flex-flow: column nowrap;
}

.flex-column-reverse {
  flex-flow: column-reverse nowrap;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}
