html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  overflow: hidden;
  background: $app-background;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  user-select: none;
  letter-spacing: -0.005rem;
  font-family: $app-font-family;
  //font-size: clamp(0.9rem, 0.6143rem + 1.1429vw, 1.3rem);
  cursor: default;
}

:focus-visible,
button:focus {
  outline: 0 none;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 0.75rem;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.25rem rgba(0, 0, 0, 0.3);
  border-radius: 0;
}

::-webkit-scrollbar-button {
  background-repeat: no-repeat;
  cursor: pointer;
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: $app-scrollbar-color;
  box-shadow: inset 0 0 0.25rem rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: $app-scrollbar-inactive-color;
}

::-webkit-scrollbar-thumb:hover {
  background: $app-scrollbar-active-color;
}

.hidden {
  display: none;
}

.invisible {
  visibility: hidden;
}

a,
a:visited {
  color: $app-link-color;
}

li {
  padding-bottom: 0.5rem;

  ul,
  ol {
    margin-top: 0.5rem;
  }
}