@use '@angular/material' as mat;
@import 'variables';

@include mat.core();
@include mat.strong-focus-indicators();
@include mat.all-component-themes($app-light-theme);
@include mat.typography-hierarchy($app-typography);
@include mat.strong-focus-indicators-theme($app-light-theme);

@import 'flex';
@import 'appbase';
@import 'apploader';
@import 'buttons';
@import 'cards';
@import 'content';
@import 'dialog';
@import 'forms';
@import 'tables';
@import 'fonts/opensans/';
@import 'material-fixes';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

body.dark {
  @include mat.all-component-colors($app-dark-theme);

  a,
  a:visited {
    color: #429dff;
  }

  .mat-mdc-menu-panel {
    color: white;
  }
}

main > ng-component {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 100%;
  position: relative;
}

.nav-menu-backdrop {
  background-color: rgba(0, 0, 0, 0.6);
}

input.mat-input-element:-internal-autofill-selected {
  background: transparent !important;
}
