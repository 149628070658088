@use 'sass:map';
@use '@angular/material' as mat;
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

$app-font-family-system: -apple-system, BlinkMacSystemFont, 'Segoe UI',
  'Open Sans', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol';
$app-font-family-opensans: 'Open Sans', sans-serif;
$app-font-family-montserrat: Montserrat, 'Open Sans', sans-serif;

$app-font-family: $app-font-family-system;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette(mat.$blue-palette, 800, 700, 900);
$app-accent: mat.define-palette(mat.$amber-palette, 500, 400, 600);
$app-warn: mat.define-palette(mat.$red-palette);
$app-success: mat.define-palette(mat.$green-palette, 600, 500, 800);

// Define an alternate dark theme.
$app-dark-primary: mat.define-palette(mat.$blue-grey-palette);
$app-dark-accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);
$app-dark-warn: mat.define-palette(mat.$deep-orange-palette);

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$app-typography: mat.define-typography-config(
  $font-family: #{$app-font-family},
  $body-1: mat.define-typography-level(1rem, 1.5rem, 500),
  $button:
    mat.define-typography-level(0.85rem, 1rem, 500, #{$app-font-family}, normal),
);

$app-light-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
    typography: $app-typography,
    density: 0,
  )
);

$app-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $app-dark-primary,
      accent: $app-dark-accent,
      warn: $app-dark-warn,
    ),
    typography: $app-typography,
    density: 0,
  )
);

@mixin primary-outline($spacing: 0.25rem, $width: 0.2rem, $hue: 700) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($app-light-theme);

  // Get the primary color palette from the color-config.
  $palette: map.get($color-config, 'primary');

  // Read the 500 hue from the accent color palette.
  outline-color: mat.get-color-from-palette($palette, $hue);
  outline-offset: $spacing;
  outline-width: $width;
  outline-style: solid;
}

@mixin primary-color($hue: 700) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($app-light-theme);

  // Get the primary color palette from the color-config.
  $palette: map.get($color-config, 'primary');

  // Read the 500 hue from the accent color palette.
  color: mat.get-color-from-palette($palette, $hue);
}

@mixin accent-color($hue: 500) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($app-light-theme);

  // Get the primary color palette from the color-config.
  $palette: map.get($color-config, 'accent');

  // Read the 500 hue from the accent color palette.
  color: mat.get-color-from-palette($palette, $hue);
}

@mixin warn-color($hue: 500) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($app-light-theme);

  // Get the primary color palette from the color-config.
  $palette: map.get($color-config, 'warn');

  // Read the 500 hue from the accent color palette.
  color: mat.get-color-from-palette($palette, $hue);
}

@mixin primary-background-color($hue: 700) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($app-light-theme);

  // Get the primary color palette from the color-config.
  $palette: map.get($color-config, 'primary');

  // Read the 500 hue from the primary color palette.
  background-color: mat.get-color-from-palette($palette, $hue);
}

@mixin accent-background-color($hue: 500) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($app-light-theme);

  // Get the accent color palette from the color-config.
  $palette: map.get($color-config, 'accent');

  // Read the 500 hue from the accent color palette.
  background-color: mat.get-color-from-palette($palette, $hue);
}

@mixin rounded() {
  border-radius: $component-border-radius !important;
}
