@use '@angular/material' as mat;
$client-color-1: #0b8d94;
$client-color-2: #f3b927;
$client-color-3: #1565c0;
$client-color-4: #858585;
$client-color-5: #eaeaea;
$hhsblue: #0b5ba7;

$darkgrey: #363636;
$dimgrey: #5c6067;
$grey: #8c8c8c;
$lightgrey: #efefef;
$ultralightgrey: #f5f5f5;

$green: mat.get-color-from-palette($app-success, default);
$lightgreen: #6fdfab;

$indigo: #3f51b5;
$red: #d21414;

$app-background: #fcfcfc;
$app-background-accent: $grey;

$app-scrollbar-color: rgba(91, 103, 113, 0.7);
$app-scrollbar-inactive-color: rgba(91, 103, 113, 0.4);
$app-scrollbar-active-color: rgba(91, 103, 113, 1);

$app-loader-color: $indigo;

$nav-background: white;
$nav-color: $darkgrey;
$nav-link-color: #ffffff;
$nav-separator-color: #{$nav-color}+ '3F';

$default-border-radius: 3px;

$app-menu-background: white;
$app-menu-color: $darkgrey;
$app-menu-button-border: 1rem solid transparent;
$app-menu-button-active-background: $lightgrey;
$app-menu-button-active-color: black;
$app-menu-button-hover-background: $lightgrey;
$app-menu-button-hover-color: white;
$app-menu-button-active-background-dark: rgba(0, 0, 0, 5);
$app-menu-button-hover-background-dark: rgba(0, 0, 0, 0.5);
$app-menu-button-hover-border: $client-color-3;

$app-border-color: rgba(0, 0, 0, 0.12);
$app-link-color: $client-color-3;

$app-footer-color: #adadad;
$app-footer-background: #172c3c;
